<template>
  <div>
    <b-card>
      <h4>Download sample files for format</h4>
      <div class="row mt-4">
        <div class="col-md-12">
          <a class="btn btn-outline-dark mr-4" href="#" @click.prevent="downloadSampleFile('extra')">Download sample extra financial data file</a>
<!--          <a class="btn btn-outline-danger" href="#" @click.prevent="downloadSampleFile('rentals')">Download sample base rental cost file</a>-->
        </div>
      </div>
    </b-card>
    <b-card>
      <div class="row">
        <div class="col-md-12">
          <form action="#" @submit.prevent="handleExcelSubmit">
            <div class="form-group">
              <label>Select record type to import</label>
              <select class="form-control" required v-model="type" @change="onTypeChanged">
                <option value="">-- choose one --</option>
<!--                <option value="rentals">Base rentals cost data import</option>-->
                <option value="extra">Extra</option>
              </select>
            </div>
            <div class="form-group mt-3">
              <vue-dropzone
                  id="file"
                  ref="file"
                  :use-custom-slot="true"
                  :options="dropzoneOptions"
              ><div class="dz-message needsclick">
                <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                <h3>Drop csv / xlsx file</h3>
              </div>
              </vue-dropzone>
            </div>

            <div class="text-center">
              <button type="submit" class="btn btn-primary">Submit Now</button>
            </div>
          </form>
        </div>
      </div>
    </b-card>
    <hr>
    <b-card v-if="data">
        <div class="row mt-4">
          <div class="col-md-12">
            <div class="row mb-2">
              <div class="col-sm-6 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display&nbsp;
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;items
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-6 col-md-6">
                <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <b-table
                  table-class="table table-centered w-100"
                  thead-tr-class="bg-light"
                  :items="tableData"
                  :fields="fields"
                  :responsive="true"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
    </b-card>

    <b-card v-if="data">
        <div class="row">
          <div class="col-md-12 text-center">
              <button  class="btn btn-dark mr-2" @click="deleteExternalImport"> <i class="fas fa-ban"></i> Delete uploaded data </button>
              <button class="btn btn-success mr-2" @click="confirmExternalImport"> <i class="fas fa-check-circle"></i> Confirm  </button>
          </div>
        </div>
    </b-card>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import {payoutService} from "../../../../apis/payout.service";
import {financialAdministrationService} from "../../../../apis/financial.administration.service";
import {confirm} from "../../../../utils/functions";

export default {
  name: "reekning",
  components: {
    vueDropzone: vue2Dropzone,
  },
  data(){
    return {
      dropzoneOptions: {
        url: '#',
        maxFilesize: 16.5,
        acceptedFiles: ".csv,.xlsx,.xls",
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFiles: 1,
      },
      type: '',
      importedDataId: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 200, 500, 1000, 1500, 2000],
      filter: null,
      filterOn: [],
      sortBy: "registeredAt",
      sortDesc: false,
      fields: [],
      tableData: [],
      data: null,
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    downloadSampleFile(type){
        if(type === "extra"){
          window.open(process.env.VUE_APP_API_BASE_URL + "/sample-files/extra-ext-file", '_blank')
        }else if (type === "rentals"){
          window.open(process.env.VUE_APP_API_BASE_URL + "/sample-files/base-cost-ext-file", '_blank')
        }
    },

    deleteExternalImport(){

      confirm('This uploaded data will be deleted', () => {
        const payload = {
          'id': this.importedDataId
        }

        this.$store.dispatch('showLoader')
        financialAdministrationService.deleteExt2021Data(payload).then((data) => {
          this.$store.dispatch('hideLoader')

          if(!data.status){
            this.$store.dispatch('error', {message:data.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message:data.message, showSwal: true})

          this.getExternal2021DataImport();

        })
      })

    },

    confirmExternalImport(){
      const payload = {
        'id': this.importedDataId
      }

      confirm('This data will be sent to the financial data', () => {
        this.$store.dispatch('showLoader')
        financialAdministrationService.confirmExt2021Data(payload).then((data) => {
          this.$store.dispatch('hideLoader')

          if(!data.status){
            this.$store.dispatch('error', {message:data.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message:data.message, showSwal: true})
          this.getExternal2021DataImport();


        })
      })

    },

    onTypeChanged(){

      this.data = null

      if (this.type === "") return;
      this.getExternal2021DataImport();
    },

    getExternal2021DataImport(){

      this.$refs.file.removeAllFiles();

      const payload = {
        "type": this.type,
      }

      this.$store.dispatch('showLoader')
      financialAdministrationService.getExt2021Data(payload).then((data) => {
        this.$store.dispatch('hideLoader')

        if(!data.status){
          this.$store.dispatch('error', {message:data.message, showSwal: true})
          return;
        }


        if(!data['extra']){
          this.data = null;
        }


        if(data['extra']){
          const header = data['extra']['header']
          const body = data['extra']['body']
          this.importedDataId = data['extra']['id']
          this.data = data['extra']['body']

          this.fields = header.map((e) => {

            return {
              label: e['name'],
              key: e['tag'],
              sortable: true,
            }

          })

          // this.fields.push('status')
          // this.fields.push('action')

          this.tableData = body.map((row, index) => {

            let obj = {};

            for (let i = 0; i < row.length; i++) {
              const cell = row[i];
              const key = cell['tag']
              obj[key] = cell['displayValue']
            }

            // obj['record'] = row;
            // obj['displayLabel'] = row[0]['fieldName']
            // obj['displayValue'] = row[0]['value']
            // obj['index'] = index


            return obj;


          })



        }

      })

    },

    handleExcelSubmit(){

      const files = this.$refs.file.getAcceptedFiles();

      if(files.length < 1){
        this.$store.dispatch('error', {
          message: 'Please select a file',
          showSwal: true
        })
        return;
      }

      const file = files[0];

      const formData = new FormData()
      formData.append('file',file)
      formData.append('type', this.type);

      this.$store.dispatch('showLoader')

      financialAdministrationService.uploadExt2021Data(formData).then((data) => {

        this.$store.dispatch('hideLoader')

        if(!data.status){
          this.$store.dispatch('error', {message:data.message, showSwal: true})
          return;
        }

        this.getExternal2021DataImport();

      })

      //post to the server from here
      // this.$store.dispatch('setUnit4Config', formData).then(() => {
      //   this.$refs.file.removeAllFiles();
      // })

    }
  },
  created() {
    console.log('rek-sch mounted')
    this.$store.dispatch('getSelectedUnit4Config', 'rek-sch')
    /// fetch provider configurations
    this.$store.dispatch('fetchProviders')
  }
}
</script>

<style scoped>

</style>